const LOCAL_DEV_ENV_NAME = "development";
const TEST_ENV_NAME = "test";

const NODE_ENV = process.env.NODE_ENV ?? LOCAL_DEV_ENV_NAME;
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV ?? NODE_ENV;

const PRODUCT_VERSION = process.env.NEXT_PUBLIC_PRODUCT_VERSION;

// ! Update this Sentry project key.
// ! Also change `project` in sentry.properties file at the root.
const SENTRY_DSN = "https://9e5f8a874fc34537903118d9ea68c001@sentry.eks-demo.shub.fish/530";

const appUrls = {
  staging: "https://pgaoaroster-staging.sportstechapp.com",
  development: "http://pgaoaroster-staging.sportstechapp.com",
  production: "https://pgaoaroster-production.sportstechapp.com",
};

const apiUrls = {
  staging: "https://pgaoaroster-staging.sportstechapp.com",
  development: "https://pgaoaroster-staging.sportstechapp.com",
  production: "https://pgaoaroster-production.sportstechapp.com",
};

const googleTags = {
  // staging: "G-B41J6EKBB8",
  // development: "G-B41J6EKBB8",
  staging: "G-29FDCE3NX8",
  production: "G-6VRK93ZKRX",
};

const kpmgGoogleTags = {
  staging: "G-V2HY6G5C31",
  production: "G-GRJ5EQ459N",
};

const APP_URL = appUrls[APP_ENV];
const API_URL = apiUrls[APP_ENV] || apiUrls["staging"];
const API_COOKIE_NAME = "sharplink-pgaoa-api-2021";
const GOOGLE_TAG = PRODUCT_VERSION === "womens" ? kpmgGoogleTags[APP_ENV] : googleTags[APP_ENV];

const settings = {
  NODE_ENV,
  APP_ENV,
  APP_URL,
  API_URL,
  GOOGLE_TAG,
  API_COOKIE_NAME,
  PRODUCT_VERSION,
  SENTRY_DSN: [LOCAL_DEV_ENV_NAME, TEST_ENV_NAME].includes(NODE_ENV) ? "" : SENTRY_DSN,
};

export default settings;
